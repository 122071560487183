import Header from './components/Header';
import DenunciasRegistro from './pages/denuncias/DenunciasRegistro';
import DashboardFiscalAdjunto from './pages/dashboard/fiscalia-adjunta/DashboardFiscalAdjunto';
import DashboardMesaEntrada from './pages/dashboard/mesa-entrada/DashboardMesaEntrada';
import PrivateRoute from './components/PrivateRoute';
import NotFound from './pages/NotFound';
import { Route, Routes } from 'react-router-dom';
import { useKeycloak } from "@react-keycloak/web";
import { checkRoles, roles } from "./Keycloak";
import DenunciasConsulta from './pages/denuncias/DenunciasConsulta';
import Causas from "./pages/causas/Causas/Causas";
import CausaContext from "./pages/causas/CausaContext/CausaContext";
import Admin from "./pages/admin/Admin";
import "./App.css";
import DashboardFiscal from './pages/dashboard/fiscalia/DashboardFiscal';
import Alerts from './pages/alerts';

function App() {
  const { keycloak } = useKeycloak();
  return (     
    <Routes>
      {/* <Route path="/causas/:id" element={
        <PrivateRoute allowedRoles={["fiscal", "fiscal-adjunto", "asistente-fiscal"]}>
          <div className="App">
            <Header />
            <div className="container">
              <CausaContext />
            </div>
          </div>
        </PrivateRoute>
      } />
      <Route path="/causas" element={
        <PrivateRoute allowedRoles={["fiscal", "fiscal-adjunto", "asistente-fiscal"]}>
          <div className="App">
            <Header />
            <div className="container">
              <Causas />
            </div>
          </div>
        </PrivateRoute>
      } /> */}
      <Route path="/alertas" element={
        <PrivateRoute allowedRoles={["mesa-de-entrada", "ddea"]}>
          <div className="App">
            <Header />
            <div className="container">
              <Alerts />
            </div>
          </div>
        </PrivateRoute>
      } />          
      <Route path="/denuncias/consultar" element={
        <PrivateRoute allowedRoles={["mesa-de-entrada", "ddea", "external-institution"]}>
          <div className="App">
            <Header />
            <div className="container">
              <DenunciasConsulta />
            </div>
          </div>
        </PrivateRoute>
      } />
      {/* <Route path="/admin" element={
        <PrivateRoute allowedRoles={["admin"]}>
          <div className="App">
            <Header />
            <div className="container">
              <Admin />
            </div>
          </div>
        </PrivateRoute>
      } />       */}
      <Route path="/denuncias/registrar" element={
        <PrivateRoute allowedRoles={["mesa-de-entrada", "external-institution"]}>
          <div className="App">
            <Header />
            <div className="container">
              <DenunciasRegistro />
            </div>
          </div>
        </PrivateRoute>
      } />
      <Route exact path="/" element={
        <PrivateRoute allowedRoles={["mesa-de-entrada", "ddea", "asistente-fiscal"]}>
          <div className="App">
            <Header />
            <div className="container">
              {dashboard(keycloak)}
            </div>
          </div>  
        </PrivateRoute>
      } />
      <Route path="/*" element={<NotFound />} />
    </Routes>
  );
}

const dashboard = (keycloak) => {
  if(!roles(keycloak)){
    return <></>
  }
  if(roles(keycloak).includes("fiscal-adjunto")){
    return <DashboardFiscalAdjunto />;
  }
  // else if (roles(keycloak).includes("fiscal") || roles(keycloak).includes("asistente-fiscal")){
  //   return <DashboardFiscal />
  // }
  if (roles(keycloak).includes("ddea") || roles(keycloak).includes("mesa-de-entrada") || roles(keycloak).includes("external-institution")){
    return <DashboardMesaEntrada />
  }  
  else return <></>;
}

export default App;
